var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-welcome"},[_c('v-alert',{staticStyle:{"margin":"20px"},attrs:{"color":"orange","icon":"mdi-alert","prominent":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.activeAccount"))+" ")])],1)],1),_c('v-card',{staticClass:"pa-3 page-login__card",attrs:{"tile":""}},[_c('v-img',{attrs:{"height":"300","src":"/assets/avatar/avatar-undefined.jpg"}}),_c('v-list',{staticClass:"pa-0",attrs:{"two-line":""}},[_c('v-list-item',{attrs:{"href":"#"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
              _vm.userData.first_name
                ? _vm.userData.first_name
                : _vm.$vuetify.lang.t('$vuetify.noDefined')
            )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t('$vuetify.firstName'))}})],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',{attrs:{"href":"#"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-email ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
              _vm.userData.email
                ? _vm.userData.email
                : _vm.$vuetify.lang.t('$vuetify.noDefined')
            )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t('$vuetify.email'))}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }